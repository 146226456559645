import React, { useRef } from "react";
import { Box } from "@chakra-ui/react";
import BlogHeader from "./Header";
import { useQuery } from "@tanstack/react-query";
import { Axios } from "../../../utils/axiosMiddleware";
import { bucket_base_url } from "../../../constants/constant";
import { base_url } from "../../../constants/constant";
import BlogItem from "./BlogItem";
import Carousel from "../../common/Carousal";

export default function Blogs() {
  const carouselRef = useRef();

  const { data: blogData } = useQuery({
    queryFn: () => Axios.get(`${base_url}/blogs`),
    select: (res) => res?.data?.records,
  });

  return (
    <Box py={20} pt={10} px={{ base: 3, md: 8 }} bg="white" maxWidth="1670px" mx={{base: 0, md: "auto"}}>
      <Box mb={8} ml={{ base: 0, md: 6 }}>
        <BlogHeader />
      </Box>
      <Carousel ref={carouselRef}>
        {blogData?.map((item) => (
          <BlogItem
            key={item?.id}
            id={item?.id}
            item={item}
            title={item?.title}
            description={item?.content}
            imageSrc={`${bucket_base_url}${item?.file[0]?.filePath}`}
            createdBy={item?.user?.name}
            category={item?.category}
          />
        ))}
      </Carousel>
    </Box>
  );
}
