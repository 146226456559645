/** @format */

import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Input, Form, Button, message, Modal } from "antd";
import Header from "../../components/Header";
import "../../styles/contact.css";
import { base_url } from "../../constants/constant";
import { Axios } from "../../utils/axiosMiddleware";
import { useDispatch } from "react-redux";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { setLoginModalVisible } from "../../redux/reducers/globalReducer";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

export default function ContactUs() {
  const formRef = useRef();
  const dispatch = useDispatch();
  const [disable, setDisable] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const { profileData } = useSelector((state) => state?.user);
  const auth = JSON.parse(localStorage.getItem("userData"));

  useEffect(() => {
    if (profileData) {
      form.setFieldsValue({
        FullName: profileData.profileName,
        email: profileData.email,
      });
    }
  }, [profileData, form]);

  const handleContactUsClick = () => {
    if (!auth) {
      dispatch(setLoginModalVisible(true)); 
      return;
    }
    setIsModalVisible(true); 
  };
 
  const onFinish = async (values) => {
    if (!auth) {
      dispatch(setLoginModalVisible(true)); 
      return;
    }
    setDisable(true);
    try {
      const response = await Axios.post(`${base_url}/tickets`, {
        name: values.FullName,
        email: values.email,
        description: values.message,
        status: "open",
        ticketType: "general_purpose",
        phoneNumber: values.phoneNumber,
      });
      message?.success(response?.data?.message);
      formRef.current.resetFields();
      setIsModalVisible(true);
      setDisable(false);
    } catch (err) {
      message?.error(err?.response?.data?.message);
      formRef.current.resetFields();
      setDisable(false);
    }
  };

  return (
    <>
      <Header />
      <Row>
        <Col span={24} className="topContainer">
          <span className="heading">
            <FormattedMessage id="contact_us" />
          </span>
          <span className="sub-heading">
            <FormattedMessage id="contact_us_sub_heading" />
          </span>
        </Col>
      </Row>
      <Row className="bottomContainer">
        <Col span={12} className="bottomLeftContainer">
          <span className="header">
            <FormattedMessage id="contact_us" />
          </span>
          <Form
            form={form}
            name="contact-form"
            initialValues={{
              FullName: profileData.profileName,
              email: profileData.email,
            }}
            onFinish={(e) => onFinish(e)}
            layout="vertical"
            className="formContainer"
            ref={formRef}
          >
            <Form.Item
              name="FullName"
              rules={[
                {
                  required: true,
                  message: <FormattedMessage id="please_enter_name" />,
                },
                {
                  pattern: "^[a-zA-Z ]+$",
                  message: <FormattedMessage id="only_alpha" />,
                },
              ]}
            >
              <Input className="form" placeholder="Full Name" />
            </Form.Item>

            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: <FormattedMessage id="enter_emai_address" />,
                },
                {
                  type: "email",
                  message: <FormattedMessage id="valid_emai_address" />,
                },
              ]}
            >
              <Input className="form" placeholder="Email Address" />
            </Form.Item>

            <Form.Item
              name="phoneNumber"
              rules={[
                {
                  required: true,
                  message: <FormattedMessage id="phone_number_enter" />,
                },
                {
                  min: 10,
                  message: <FormattedMessage id="phone_number_must_be" />,
                },
              ]}
            >
              <PhoneInput
                inputProps={{
                  name: "phone",
                  required: true,
                }}
                countryCodeEditable={false}
                country={"us"}
                prefix="+"
                inputStyle={{
                  width: "100%",
                  height: "70px",
                  backgroundColor: "white",
                  marginTop: "8px",
                }}
              />
            </Form.Item>

            <Form.Item
              name="message"
              rules={[
                {
                  required: true,
                  message: <FormattedMessage id="enter_message" />,
                },
              ]}
            >
              <Input.TextArea className="form" rows={5} placeholder="Message" />
            </Form.Item>

            <Form.Item>
              <Button
                className="formButton"
                loading={disable}
                htmlType="submit"
                onClick={handleContactUsClick}
              >
                <FormattedMessage id="send_message" />
              </Button>
            </Form.Item>
          </Form>
        </Col>
        <Col span={1}></Col>
        <Col span={11} className="bottomRightContainer">
          <span className="top-text">
            <FormattedMessage id="contact_us_p1" />
          </span>
          <span className="text-header">
            {" "}
            <FormattedMessage id="llc" />
          </span>
          <span className="bottom-text">
            <FormattedMessage id="contact_us_p2" />
          </span>
          <span className="end-text">
            <FormattedMessage id="thank_you" />
          </span>
        </Col>
      </Row>
      <Modal
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={() => setIsModalVisible(false)}
        bodyStyle={{
          height: "100px",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
        okButtonProps={{ style: { backgroundColor: "#E5BE54" } }}
      >
        <p style={{ fontSize: "20px", width: "100%" }}>
          <FormattedMessage id="we_have_received" />
        </p>
      </Modal>
    </>
  );
}
