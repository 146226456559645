import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../../styles/home.css";
import { Col, Dropdown, Row, Tooltip } from "antd";
import { Box, Button, Text } from "@chakra-ui/react";
import { HamMenu } from "./HamMenu";
import { routes_data } from "../../constants/StaticData";
import { FormattedMessage } from "react-intl";
import { FaRegUserCircle, FaSignOutAlt } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { setLoginModalVisible } from "../../redux/reducers/globalReducer";
import { useIntl } from "react-intl";
import { Login } from "../login";
import { RxDashboard } from "react-icons/rx";
import { useQuery } from "@tanstack/react-query";
import { authAxios } from "../../utils/axiosMiddleware";
import { setProfileData } from "../../redux/reducers/UserReducer";

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { locale } = useIntl();
  const [hamMenu, setHamMenu] = useState(false);
  const [hamburgerActive, setHamburgerActive] = useState(false);
  const [mobileView, setMobileView] = useState(window.innerWidth < 1210);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 560);

  useEffect(() => {
    const handleResize = () => {
      setMobileView(window.innerWidth < 1210);
      setIsSmallScreen(window.innerWidth < 560);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleHamMenu = () => {
    setHamMenu(!hamMenu);
    setHamburgerActive(!hamburgerActive); 
  };

  const auth = JSON.parse(localStorage.getItem("userData"));
  const items = [
    {
      label: <FormattedMessage id="view_dashboard" />,
      key: "0",
      icon: <RxDashboard />,
      onClick: () => navigate("/user-dashboard"),
    },
    {
      label: <FormattedMessage id="Logout" />,
      key: "2",
      icon: <FaSignOutAlt />,
      onClick: () => {
        localStorage.removeItem("userData");
        window.location.reload();
      },
    },
  ];

  const { data: profileData, isError, isSuccess } = useQuery({
    enabled: !!auth,
    queryKey: ["me"],
    queryFn: () => authAxios.get("/auth/me"),
    retry: false,
    select: (data) => data?.data,
  });

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        setProfileData({
          profileName: profileData?.name,
          email: profileData?.email,
          roles: profileData?.["userRolePermissions"],
          permissions: profileData?.permissions,
          id: profileData?.id,
          userLanguage: profileData?.["languagePreference"],
        })
      );
    } else if (isError) {
      localStorage.removeItem("userData");
      window.location.href = "/";
    }
  }, [isSuccess, isError, profileData, dispatch]);

  return (
    <>
      <Row className="home-header-container">
        <Col xs={4} md={4} className="logo-and-hamburger">
          {mobileView && (
            <>
            <img
              src="/icons/Hamburger.png"
              className="hamburgerMenu"
              alt="hamburgerMenuIcon"
              onClick={handleHamMenu}
              style={{
                order: 1,
                filter: hamburgerActive ? "invert(100%)" : "none", 
              }}
            />
            </>
          )}
          <Col xs={16} className="home-logo" style={{ order: 2, textAlign: "center" }}>
            <Link to="/">
              <img
                src={
                  location.pathname.includes("property-details")
                    ? "/icons/Logo Colored.svg"
                    : "/icons/Logo Colored.svg"
                }
                alt="logo"
                className="logo-image"
              />
            </Link>
          </Col>
        </Col>
        <Col xs={10} lg={16}>
          {!mobileView ? (
            <Row justify={"center"}>
              {routes_data?.map((route) => (
                <Col span={3} className="header-alignment" key={route?.name}>
                  <div onClick={() => navigate(route?.path)} style={{ color: "black", cursor: "pointer" }}>
                    <FormattedMessage id={route?.name} />
                  </div>
                </Col>
              ))}
            </Row>
          ) : null}
        </Col>
        <Col xs={4} lg={4} className="user-login-col">
          {!auth ? (
            isSmallScreen ? (
              <Box
              as="div"
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              width={locale === "ar" ? "160%" : "100%"}
              ml={locale === "ar" ? "50px" : "75px"}
            >
              <Button
                onClick={() => dispatch(setLoginModalVisible(true))}
                colorScheme="white"
                size="lg"
                color="black"
                fontSize="16px"
                fontWeight="bold"
                borderColor="blackAlpha.300" 
                borderWidth="1.3px" 
                borderRadius="12px"
                width="200px" 
                height="45px"
                boxShadow="xl"
                _hover={{
                  backgroundColor: "#e5be54",
                  boxShadow: "xl",
                }}
              >
                <FormattedMessage id="Login" />
              </Button>
            </Box>
            ) : (
              <>
                <FaRegUserCircle
                  className="user-icon"
                  onClick={() => dispatch(setLoginModalVisible(true))}
                />
                <span
                  className="user-icon-text"
                  onClick={() => dispatch(setLoginModalVisible(true))}
                >
                  <Box display="flex" alignItems="center">
                    <FormattedMessage id="Login" />
                    <Text mx={2}>/</Text>
                    <FormattedMessage id="Register" />
                  </Box>
                </span>
              </>
            )
          ) : (
            <>
              <Tooltip title="Dashboard">
                <RxDashboard
                  className="dashboard-icon"
                  onClick={() => navigate("/user-dashboard")}
                />
              </Tooltip>
              <Dropdown menu={{ items }}>
                <span className="user-icon-text">
                  <FaRegUserCircle className="user-login-icon" />
                  {profileData?.name}
                </span>
              </Dropdown>
            </>
          )}
        </Col>
      </Row>
      {hamMenu && <HamMenu handleHamMenu={handleHamMenu} />}
      <Login />
    </>
  );
};

export default Header;
