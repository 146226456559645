import React, { useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import Header from './Header';
import PropertyTypeSection from './PropertyTypes';
import PropertyGrid from './PropertyGrid';

const ExploreApartments = () => {
  const [selectedType, setSelectedType] = useState("OF");

  return (
    <Box backgroundColor="#f5f5f5" pt={20} pb={40} px={{ base: 5, md: 6 }}>
      <Box maxWidth="1470px" mx="auto">
        <Flex 
          pl={{ base: "9px", md: "10px" }}
          justifyContent={{ base: "center", md: "space-between" }} 
          alignItems="center" 
          flexDirection={{ base: "column", md: "row" }} 
          mb={8}
        >
          <Header />
          <PropertyTypeSection
            selectedType={selectedType}
            onSelectType={setSelectedType}
          />
        </Flex>
        <PropertyGrid selectedType={selectedType} />
      </Box>
    </Box>
  );
};

export default ExploreApartments;
