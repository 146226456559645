import React from "react";
import { Box, Stack, SkeletonCircle, Skeleton, SkeletonText } from "@chakra-ui/react";

const AgentInfoSkeleton = () => {
  return (
    <Box p={5} borderRadius="md" border="1px" borderColor="gray.200">
      <Stack
        direction={{ base: "column", md: "row" }} 
        spacing={4}
        align="center"
        mb={4}
      >
        <Skeleton height="40px" width={{ base: "80%", md: "100px" }} />
        <Skeleton height="40px" width={{ base: "80%", md: "100px" }} />
        <Skeleton height="40px" width={{ base: "80%", md: "100px" }} />
      </Stack>
      <Box mb={4}>
        <SkeletonText noOfLines={3} spacing="4" />
      </Box>
      <Stack direction={{ base: "column", md: "row" }} align="center" spacing={4}>
        <SkeletonCircle size="12" />
        <Box>
          <Skeleton height="10px" width={{ base: "80%", md: "150px" }} mb={2} />
          <Skeleton height="10px" width={{ base: "60%", md: "100px" }} />
        </Box>
      </Stack>
    </Box>
  );
};

export default AgentInfoSkeleton;
