import { Box, VStack, Text, Spinner, Flex } from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { FormattedMessage, useIntl } from "react-intl";

function AccountMenu({ onSelect, selectedComponent, savedPropertiesCount, contactedPropertiesCount, savedSearchesCount, loading }) {
  const { locale } = useIntl();
  const isRtl = locale === "ar";

  return (
    <Box 
      w={{ base: "100%", md: "250px" }}
      borderRight={{ base: "none", md: "1px solid #e0e0e0" }}
      p="4"
      bg="white"
    >
      <Text 
        fontSize="lg" 
        fontWeight="bold" 
        mb="4"
        textAlign={{ base: "center", md: "left" }}
      >
        <FormattedMessage id="accountMenu.title" defaultMessage="My Account" />
      </Text>
      <VStack 
        align="stretch" 
        spacing="1"
        display={{ base: "flex", md: "block" }}
      >
        <Box
          as="button"
          onClick={() => onSelect("personalInfo")}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p="3"
          bg="white"
          borderLeft={selectedComponent === "personalInfo" ? "4px solid red" : "4px solid transparent"}
          _hover={{ bg: "gray.100" }}
        >
          <Text fontSize="sm" color="gray.700">
            <FormattedMessage id="accountMenu.personalInfo" defaultMessage="Personal Information" />
          </Text>
          <ChevronRightIcon />
        </Box>

        <Box
          as="button"
          onClick={() => onSelect("contactedProperties")}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p="3"
          bg="white"
          borderLeft={selectedComponent === "contactedProperties" ? "4px solid red" : "4px solid transparent"}
          _hover={{ bg: "gray.100" }}
        >
          <Flex fontSize="sm" color="gray.700" alignItems="center" gap={1}>
            {isRtl ? (
              <>
                {loading ? <Spinner size="xs" /> : <Text as="span">({contactedPropertiesCount})</Text>}
                <FormattedMessage id="accountMenu.contactedProperties" defaultMessage="Contacted properties" />
              </>
            ) : (
              <>
                <FormattedMessage id="accountMenu.contactedProperties" defaultMessage="Contacted properties" />
                {loading ? <Spinner size="xs" /> : <Text as="span">({contactedPropertiesCount})</Text>}
              </>
            )}
          </Flex>
          <ChevronRightIcon />
        </Box>
        <Box
          as="button"
          onClick={() => onSelect("savedProperties")}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p="3"
          bg="white"
          borderLeft={selectedComponent === "savedProperties" ? "4px solid red" : "4px solid transparent"}
          _hover={{ bg: "gray.100" }}
        >
          <Text fontSize="sm" color="gray.700">
            <FormattedMessage id="accountMenu.savedProperties" defaultMessage="Saved properties" />{" "}
            {loading ? <Spinner size="xs" /> : `(${savedPropertiesCount})`}
          </Text>
          <ChevronRightIcon />
        </Box>

        <Box
          as="button"
          onClick={() => onSelect("savedSearches")}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p="3"
          bg="white"
          borderLeft={selectedComponent === "savedSearches" ? "4px solid red" : "4px solid transparent"}
          _hover={{ bg: "gray.100" }}
        >
          <Text fontSize="sm" color="gray.700">
            <FormattedMessage id="accountMenu.savedSearches" defaultMessage="Saved Searches" />{" "}
            {loading ? <Spinner size="xs" /> : `(${savedSearchesCount})`}
          </Text>
          <ChevronRightIcon />
        </Box>
      </VStack>
    </Box>
  );
}

export default AccountMenu;
