import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Flex, Grid, Spinner, useBreakpointValue, Button } from "@chakra-ui/react";
import "../../styles/home.css";
import { useQuery } from "@tanstack/react-query";
import {
  fetchPropertyList,
  getLikeProperty,
} from "../../api/Property/propertyApi";
import { setListingQueryParams } from "../../redux/reducers/propertyReducer";
import Header from "../../components/Properties/header";
import PropertyTypes from "../../components/Properties/PropertyTypes";
import { useDispatch } from "react-redux";
import { PropertyCard } from "../Properties/PropertyCard";
import { SkeletonCard } from "../common/Skeleton";
import { FormattedMessage } from "react-intl";

const initialStateData = {
  title_en: '',
  propertyType: '',
  propertyPurpose: '',
  bedroom: '',
  bathroom: '',
  minSize: '',
  maxSize: '',
  community: '',
  completion_status: '',
};

const Property = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedType, setSelectedType] = useState("sale");
  const [stateData, setStateData] = useState(initialStateData);
  const take = useBreakpointValue({ base: 3, md: 8 }) || 8;

  const { data: propertyList, isLoading } = useQuery({
    queryKey: ["Property", selectedType, take],
    queryFn: () =>
      fetchPropertyList({ take, skip: 0, property_purpose: selectedType }),
    select: (res) => res?.data?.records,
    enabled: !!take,
  });

  const auth = JSON.parse(localStorage.getItem("userData"));

  const {
    data: favorite,
    isLoading: favoriteLoading,
    refetch,
  } = useQuery({
    enabled: !!auth?.access_token,
    queryKey: ["PropertyLikes"],
    queryFn: () => getLikeProperty(auth?.id),
    select: (res) => res?.data?.records,
  });

  const handleViewAll = () => {
    dispatch(
      setListingQueryParams({
        skip: 0,
        take: 6,
        currentPage: 1,
        property_purpose: selectedType,  
      })
    );
    navigate('/properties', { state: { selectedTab: selectedType } });
  };

  const buttonSize = useBreakpointValue({ base: "sm", md: "md" });

  return (
    <Box backgroundColor="white" pt={20} pb={40} px={{ base: 3, md: 6 }}>
    <Box maxWidth="1470px" mx="auto">
      <Flex justifyContent="space-between" flexDirection={{ base: "column", md: "row" }} alignItems="center" mb={8}>
        <Header />
        <PropertyTypes 
        selectedType={selectedType} 
        onSelectType={(type) => {
          setSelectedType(type); 
        }}
        />
      </Flex>

      <Grid templateColumns={{ base: 'repeat(1, 1fr)', sm: "repeat(2, 1fr)", lg: 'repeat(3, 1fr)', xl: 'repeat(4, 1fr)' }} gap={6}>
        {(isLoading || favoriteLoading)
            ? Array.from({ length: take }).map((_, index) => (
                <SkeletonCard key={index} />
              ))
            : propertyList?.map((item) => {
                const likeArr = favorite?.filter(
                  (like) => like?.propertyId === item?.id
                );
                return (
                  <Box key={item?.id}>
                    <PropertyCard item={item} liked={likeArr} refetch={refetch} />
                  </Box>
                );
              })}
        </Grid>

      <Flex className="property-view-all-button-conatainer">
        <Button
          size={buttonSize}
          onClick={() => {
            dispatch(
              setListingQueryParams({
                ...stateData,
                skip: 0,
                take: 6,
                currentPage: 1,
                property_purpose: selectedType,
                propertyType: stateData.propertyType,
              })
            );
            navigate('/properties', { state: {skip: 0, take: 6, selectedTab:  selectedType === 'sale' ? 'Buy' : selectedType === 'rent' ? 'Rent': selectedType, property_purpose: selectedType, propertyType: stateData.propertyType } } );
          }}
        >
          <FormattedMessage id="view_all" />
        </Button>
      </Flex>
    </Box>
  </Box>
);
};

export default Property;
