import React from "react";
import { Box, Skeleton, Grid } from "@chakra-ui/react";

const ImageCarouselSkeleton = () => {
  return (
    <Box>
      <Grid
        templateColumns={{ base: "1fr", sm: "2fr 1fr" }}
        gap={4}
      >
        <Skeleton height={{ base: "300px", sm: "750px" }} borderRadius="md" />
        <Grid 
          templateRows={{ base: "1fr", sm: "repeat(2, 1fr)" }} 
          gap={4} 
          display={{ base: "none", sm: "grid" }}
        >
          <Skeleton height={{ base: "150px", sm: "367px" }} borderRadius="md" />
          <Skeleton height={{ base: "150px", sm: "367px" }} borderRadius="md" />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ImageCarouselSkeleton;
