import React from "react";
import { Box, Heading, Text } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";

const BlogHeader = () => {
  return (
    <Box>
      <Heading as="h2" size="xl" fontWeight="bold" color="black" mb={2} px={{base: 6, md: 0}}>
      <FormattedMessage id="blog_header_title" defaultMessage="From Our Blog" />
      </Heading>
      <Text fontSize="lg" color="gray.500" px={{base: 6, md: 0}}>
      <FormattedMessage 
        id="blog_header_subtitle" 
        defaultMessage="Stay informed and inspired with the latest news, tips, and trends in real estate." 
      />
      </Text>
    </Box>
  );
};

export default BlogHeader;
