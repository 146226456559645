import React, { useCallback, useState, Suspense } from 'react';
import { Box, Spinner } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { fetchPropertyList } from '../../api/Property/propertyApi'; 
import Header from './Header';
import Carousel from '../common/Carousal'; 
import FullMapView from './FullMapView'; 
import TownPreview from './TownPreview'; 

const locationOption = [
  { community: 'Mohamed Bin Zayed City', lat: 24.3345, lng: 54.5643 },
  { community: 'Al Reem Island', lat: 24.4958, lng: 54.3964 },
  { community: 'Al Raha Beach', lat: 24.4364, lng: 54.6167 },
  { community: 'Mussafah', lat: 24.3456, lng: 54.4995 },
  { community: 'Sas Al Nakheel', lat: 24.4218, lng: 54.5813 },
  { community: 'Al Bateen', lat: 24.4670, lng: 54.3444 },
  { community: 'Masdar City', lat: 24.4256, lng: 54.6161 },
  { community: 'Tourist Club Area', lat: 24.4956, lng: 54.3840 },
  { community: 'Saadiyat Island', lat: 24.5343, lng: 54.4325 },
  { community: 'Khalifa City', lat: 24.4203, lng: 54.5736 },
  { community: 'Al Khalidiya', lat: 24.4625, lng: 54.3338 },
  { community: 'Corniche Road', lat: 24.4766, lng: 54.3217 },
  { community: 'Electra Street', lat: 24.4893, lng: 54.3818 },
  { community: 'The Marina', lat: 24.4795, lng: 54.3219 },
  { community: 'Yas Island', lat: 24.4951, lng: 54.6085 },
  { community: 'Al Hudayriat Island', lat: 24.4244, lng: 54.2865 },
  { community: 'Rawdhat Abu Dhabi', lat: 24.4147, lng: 54.5041 },
  { community: 'Capital Centre', lat: 24.4162, lng: 54.4517 },
  { community: 'Danet Abu Dhabi', lat: 24.4312, lng: 54.4173 },
  { community: 'Grand Mosque District', lat: 24.4129, lng: 54.4756 },
  { community: 'Airport Road', lat: 24.4261, lng: 54.4347 },
  { community: 'Hamdan Street', lat: 24.4906, lng: 54.3678 },
  { community: 'Eastern Road', lat: 24.4582, lng: 54.4111 },
];

const ExploreTowns = () => {
  const [isMapOpen, setIsMapOpen] = useState(false);
  const [selectedTown, setSelectedTown] = useState(null);

  const { data: propertyList, isSuccess } = useQuery({
    queryKey: ['FullPropertyList'],
    queryFn: fetchPropertyList, 
    select: (res) => res?.data?.records,
  });

  const getPropertyCountForTown = (communityName) => {
    if (isSuccess && propertyList) {
      return propertyList.filter(
        (property) => property.community === communityName
      ).length;
    }
    return 0;
  };

  const openMap = useCallback((town) => {
    setSelectedTown(town); 
    setIsMapOpen(true); 
  }, []);

  const closeMap = useCallback(() => {
    setIsMapOpen(false); 
    setSelectedTown(null);
  }, []);

  return (
    <Box backgroundColor="white" pt={20} pb={10} px={{ base: 3, md: 0 }}>
      <Box maxWidth="1610px" mx="auto" p={6}>
        <Box 
        pl={{ base: 0, md: "60px" }} 
        textAlign={{ base: "left", md: "left" }}
        >
          <Header />
        </Box>
          {!isMapOpen ? (
            <Suspense fallback={<Spinner size="xl" />}>
              <Box
              display="flex"
              justifyContent="center" 
              alignItems="center" 
              width="100%" 
              mx={{ base:"0px", md: "auto" }}
              position="relative"
              sx={{
                '.carousel-root': {
                  width: '100%'
                },
                '.carousel .slider-wrapper': {
                  display: 'flex',
                  justifyContent: 'center'
                },
                '.carousel .slider': {
                  display: 'flex',
                  alignItems: 'center'
                },
                '@media screen and (max-width: 48em)': {
                  '.carousel-root': {
                    paddingLeft: '0',
                    marginLeft: '-5%' 
                  }
                }
              }}
            >
              <Carousel>
                {locationOption?.map((town, index) => (
                  <Box
                    key={index}
                    onClick={() => openMap(town)}
                    position="relative"
                    sx={{
                      '@media screen and (max-width: 48em)': {
                        width: '90%',
                        margin: '0 auto',
                        marginRight: '30px',
                        transform: 'translateX(-5%)', 
                        '&:not(:last-child)': {
                          marginRight: '10%'
                        }
                      },
                      '@media screen and (min-width: 48em)': {
                        margin: '30px',
                        width: 'auto'
                      }
                    }}
                  >
                      <TownPreview
                        townName={town?.community}
                        numProperties={getPropertyCountForTown(town.community)}
                        isLoading={!isSuccess}
                      />
                    </Box>
                  ))}
                </Carousel>
              </Box>
            </Suspense>
          ) : (
            <FullMapView town={selectedTown} propertyList={propertyList} onClose={closeMap} />
          )}
        </Box>
      </Box>
  );
};

export default ExploreTowns;
