import { Col, Row } from "antd";
import React from "react";
import "../../styles/about.css";

export const VisionMission = ({
  imageClass,
  contentClass,
  heading,
  text,
  headingClass,
  textClass,
}) => {
  return (
    <Row>
      <Col xs={24} className="mission-alignment">
        <div className="mission-container">
          <div className={imageClass}></div>
          <div className={contentClass}>
            <span className={headingClass}>{heading}</span>
            <span className={textClass}>{text}</span>
          </div>
        </div>
      </Col>
    </Row>
  );
};