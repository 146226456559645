import React from "react";
import { Box, Flex, Text, VStack, List, ListItem, IconButton } from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const TermsCondition = () => {
  const navigate = useNavigate();

  return (
    <Box p={4}>
      <Flex
        align="center"
        mb={4}
        cursor="pointer"
        onClick={() => navigate(-1)}
        maxW="full"
      >
        <IconButton
          icon={<ArrowBackIcon />}
          aria-label="Back"
          variant="ghost"
          size="md"
          mr={2}
        />
        <Text fontSize={{ base: "sm", md: "md", lg: "lg" }} fontWeight="bold">
          <FormattedMessage id="back" />
        </Text>
      </Flex>

      <Box textAlign="center" mb={6}>
        <Text
          fontSize={{ base: "2xl", md: "3xl", lg: "4xl" }}
          fontWeight="bold"
        >
          <FormattedMessage id="t_and_s" />
        </Text>
      </Box>

      <Box textAlign="center" mb={6}>
        <Text fontSize={{ base: "md", md: "lg", lg: "xl" }}>
          <FormattedMessage id="last_update" />
        </Text>
      </Box>

      <VStack
        align="start"
        spacing={4}
        p={{ base: 2, md: 4, lg: 6 }}
        borderRadius="md"
        boxShadow="md"
        bg="gray.50"
      >
        <List spacing={6} w="full">
          <ListItem>
            <Text fontSize={{ base: "sm", md: "md", lg: "lg" }}>
              <FormattedMessage id="welcome_intro" />
            </Text>
          </ListItem>

          <ListItem>
            <Text fontSize={{ base: "sm", md: "md", lg: "lg" }}>
              <FormattedMessage id="service_offered" />
            </Text>
          </ListItem>

          <ListItem>
            <Text fontSize={{ base: "sm", md: "md", lg: "lg" }}>
              <FormattedMessage id="user_registration" />
            </Text>
          </ListItem>

          <ListItem>
            <Text fontSize={{ base: "sm", md: "md", lg: "lg" }}>
              <FormattedMessage id="property_listing" />
            </Text>
          </ListItem>

          <ListItem>
            <Text fontSize={{ base: "sm", md: "md", lg: "lg" }}>
              <FormattedMessage id="property_search" />
            </Text>
          </ListItem>

          <ListItem>
            <Text fontSize={{ base: "sm", md: "md", lg: "lg" }}>
              <FormattedMessage id="property_valuation" />
            </Text>
          </ListItem>

          <ListItem>
            <Text fontSize={{ base: "sm", md: "md", lg: "lg" }}>
              <FormattedMessage id="property_marketing" />
            </Text>
          </ListItem>

          <ListItem>
            <Text fontSize={{ base: "sm", md: "md", lg: "lg" }}>
              <FormattedMessage id="intellectual_property" />
            </Text>
          </ListItem>

          <ListItem>
            <Text fontSize={{ base: "sm", md: "md", lg: "lg" }}>
              <FormattedMessage id="intellectual_property1" />
            </Text>
          </ListItem>

          <ListItem>
            <Text fontSize={{ base: "sm", md: "md", lg: "lg" }}>
              <FormattedMessage id="intellectual_property2" />
            </Text>
          </ListItem>
        </List>
      </VStack>
    </Box>
  );
};

export default TermsCondition;
