import { Box, Text, Image, Spinner } from '@chakra-ui/react';

const TownPreview = ({ townName, numProperties, isLoading }) => {
  return (
    <Box
      position="relative"
      borderRadius="10px"
      overflow="hidden"
      width={{ base: "100%", md: "400px" }} 
      height={{ base: "auto", md: "400px" }} 
      m={{ base: "0px", md: "19px" }} 
      mr={{ base: "19px", md: "0px" }}
      p={{ base: 3, md: 9 }} 
      boxShadow="lg"
      cursor="pointer"
      display="flex" 
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Image
        src="/icons/mapView.jpeg"
        alt={townName}
        width="100%"
        height="70%"
        objectFit="cover"
      />
      <Box textAlign="center" mt={3} p={3}>
        <Text fontWeight="bold">{townName}</Text>
        <Text fontSize="sm">
          {isLoading ? (
            <Spinner size="sm" color="#ECC94BE6" mr={2} />
          ) : (
            `${numProperties}`
          )}{" "}
          Properties
        </Text>
      </Box>
    </Box>
  );
};

export default TownPreview;
