import { useQuery } from "@tanstack/react-query";
import React from "react";
import { base_url } from "../../constants/constant";
import { Axios } from "../../utils/axiosMiddleware";
import { Col, Row, Spin } from "antd";
import { BlogCard } from "./blogCard";

export const BlogListing = () => {
  const { data: blogs, isLoading } = useQuery({
    queryFn: () => Axios.get(`${base_url}/blogs`),
    select: (res) => res?.data?.records,
  });

  return (
    <Spin spinning={!!isLoading} style={{ marginTop: "90px" }}>
      <Row
        gutter={[16, 24]} 
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "70px",
          padding: "0 15px", 
        }}
      >
        {blogs?.map((item) => {
          return (
            <Col
              xs={24}
              sm={12}
              md={10}
              lg={7}
              xl={7}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BlogCard item={item} />
            </Col>
          );
        })}
      </Row>
    </Spin>
  );
};