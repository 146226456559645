import React, { useState, useCallback, useEffect } from "react";
import { Box, Flex, Text, Image, Button, Icon, HStack, Grid, GridItem, Tooltip, Badge, useToast, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, useDisclosure } from "@chakra-ui/react";
import { FaPhone, FaEnvelope, FaWhatsapp, FaHeart, FaBed, FaBath, FaTrash } from "react-icons/fa";
import { MdLocationOn, MdSquareFoot } from "react-icons/md";
import { CiHeart } from "react-icons/ci";
import { useIntl } from "react-intl";
import { useMutation } from "@tanstack/react-query";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useDebouncedCallback } from "use-debounce";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ContactForm from "../PropertyDetails/ContactForm";
import { disLikeProperty, likeProperty } from "../../api/Property/propertyApi";
import { deleteLead } from "../../api/lead/leadApi";
import { setLoginModalVisible } from "../../redux/reducers/globalReducer";
import { calculateDaysAgo } from "../../utils/Helper";
import { bucket_base_url, base_url } from "../../constants/constant";

export const PropertyCard = ({ 
  item, 
  liked, 
  refetch, 
  refetchSavedProperties, 
  setDislikeTrigger, 
  isContacted, 
  leadId,
  isSavedProperties,
  isSavedSearches, 
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { profileData } = useSelector((state) => state?.user);
  const auth = JSON.parse(localStorage.getItem("userData"));
  const toast = useToast();
  const [agent, setAgent] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const [like, setLike] = useState(liked?.length > 0 ? true : false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [disable, setDisable] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { mutate: mutateLike } = useMutation({
    mutationFn: (data) => likeProperty(data),
  });
  const { mutate: mutateDislike } = useMutation({
    mutationFn: () => disLikeProperty(liked[0]?.id),
  });

  const fetchAgentDetails = useCallback(async (userId) => {
    try {
      const response = await axios.get(`${base_url}/users/${userId}`, {
        headers: { "ngrok-skip-browser-warning": true },
      });
      setAgent(response.data);
    } catch (error) {
      console.error("Failed to fetch agent details:", error);
    }
  }, []);

  useEffect(() => {
    if (item?.userId) fetchAgentDetails(item.userId);
  }, [fetchAgentDetails, item?.userId]);

  const handleWhatsAppClick = () => {
    const phoneNumber = agent?.phone || "971547562839";
    const message = intl.formatMessage(
      { id: "whatsapp_message" },
      { referenceNumber: item?.reference_number }
    );
    const whatsappLink = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
    window.open(whatsappLink, "_blank");
  };

  const handleLike = useDebouncedCallback(() => {
    if (auth) {
      setLike((prev) => !prev);
      if (like) {
        mutateDislike("", {
          onSuccess: () => {
            refetch();
            refetchSavedProperties();
            setDislikeTrigger(true);
            toast({
              title: "Property removed from favorites.",
              status: "success",
              duration: 3000,
              isClosable: true,
              position: "top",
            });
          },
        });
      } else {
        const body = {
          propertyId: item.id,
        };
        mutateLike(body, {
          onSuccess: () => {
            refetch();
            refetchSavedProperties();
            toast({
              title: "Property added to favorites.",
              status: "success",
              duration: 3000,
              isClosable: true,
              position: "top",
            });
          },
        });
      }
    } else {
      dispatch(setLoginModalVisible(true));
    }
  }, 100);

  const { mutate: mutateDelete } = useMutation({
    mutationFn: () => deleteLead(leadId), 
    onSuccess: () => {
      refetch();
      toast({
        title: "Property removed from contacted list.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    },
    onError: () => {
      toast({
        title: "Failed to delete contacted property.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    },
  });

  const handleDelete = () => {
    mutateDelete();
  };

  const handleCallClick = () => {
    if (agent?.phone || "971565083186") {
      setShowTooltip(true);
      setTimeout(() => setShowTooltip(false), 3000); 
    } else {
      alert("Agent contact number is not available.");
    }
  };

  return (
    <Box 
      borderWidth="1px" 
      borderRadius="lg" 
      overflow="hidden" 
      boxShadow="lg" 
      maxW={{base: "120%", md: "900px"}} 
      m="auto"
      maxH={{ base: "auto", md: "308px" }}
    >
      <Grid 
        templateColumns={{ base: "1fr", md: "1.5fr 2fr" }}
        gap={4}
      >
        <GridItem>
          <Box position="relative" height="100%">
            <Carousel showArrows autoPlay={false} infiniteLoop showThumbs={false} showIndicators={false} showStatus={false} selectedItem={currentSlide} onChange={setCurrentSlide}>
              {item?.file?.map((image, index) => (
                <Box key={index} width="100%" height="100%">
                  <Box position="absolute" top="2" left="2" bg="blackAlpha.600" p="2" borderRadius="md" color="white">
                    <Text fontSize="sm">{item?.offering_type}</Text>
                  </Box>
                  <Box position="absolute" top="2" right="2">
                    <button onClick={handleLike}>
                      {like ? <Icon as={FaHeart} color="red.500" boxSize="6" /> : <Icon as={CiHeart} color="gray.500" boxSize="6" />}
                    </button>
                  </Box>
                  <Image src={image?.filePath?.includes("http") ? image.filePath : `${bucket_base_url}${image?.filePath}`} alt="Property" objectFit="cover" width="90%" height={{ base: "170px", md: "250px" }} />
                </Box>
              ))}
            </Carousel>
          </Box>
        </GridItem>

        <GridItem>
          <Box onClick={() => navigate(`/property-details/${item?.id}`, { state: profileData })} cursor="pointer" ml={{base: 3, md: 0}}>
          <Tooltip label={item?.title_en}>
              <Text fontSize="md" color="gray.500" mt={{ base: 0, md: 5 }}>
                {intl.formatMessage({ id: "apartment", defaultMessage: "Apartment" })}
              </Text>
            </Tooltip>
            <Text fontSize="2xl" fontWeight="bold" color="gray.700" mb="2">
              {intl.formatMessage(
                { id: "aed", defaultMessage: "{price} AED" },
                { price: item?.price }
              )}
            </Text>
            <Text fontSize="lg" color="gray.600" mb="4">{item?.title_en}</Text>

            <Flex alignItems="center" mb="3" mt={{base: 0, md: "60px"}}>
              <Icon as={MdLocationOn} color="gray.500" mr="1" />
              <Text fontSize="md" color="gray.500">
                {item?.community}, {item?.city}
              </Text>
            </Flex>

            <Flex alignItems="center" color="gray.600" mb="4">
              <Icon as={FaBed} mr="1" />
              <Text fontSize="sm">
                {intl.formatMessage(
                  { id: "beds", defaultMessage: "{count} Beds" },
                  { count: item?.bedroom }
                )}
              </Text>
              <Text mx="2">|</Text>
              <Icon as={FaBath} mr="1" />
              <Text fontSize="sm">
                {intl.formatMessage(
                  { id: "baths", defaultMessage: "{count} Baths" },
                  { count: item?.bathroom }
                )}
              </Text>
              <Text mx="2">|</Text>
              <Icon as={MdSquareFoot} mr="1" />
              <Text fontSize="sm">
                {intl.formatMessage(
                  { id: "sqft", defaultMessage: "{size} SQFT" },
                  { size: item?.size }
                )}
              </Text>
            </Flex>
          </Box>
        </GridItem>
      </Grid>

      <Box 
        bg="gray.100" 
        p={{ base: 3, md: 2 }}
        mb={{base: 0, md: 3}}
        display="flex"
        flexDirection={{ base: "column", md: "row" }}
        alignItems="center"
        gap={{ base: 3, md: 0 }}
        justifyContent="space-between"
      >
        <Flex alignItems="center" w={{ base: "100%", md: "auto" }}>
          {agent && (
            <Image
              borderRadius="full"
              boxSize="40px"
              src={agent.photo || "/path-to-placeholder-image.jpg"}
              alt="Agent"
              mr="2"
            />
          )}
          <Text fontSize="sm" color="gray.600">
            {intl.formatMessage(
              { id: "listed_by", defaultMessage: "Listed {daysAgo} by {agentName}" },
              {
                daysAgo: item?.createdAt ? calculateDaysAgo(item.createdAt) : "N/A",
                agentName: agent?.name || intl.formatMessage({ id: "agentInfo.defaultAgentName", defaultMessage: "Agent" })
              }
            )}
          </Text>
        </Flex>
        <HStack 
      spacing={{ base: 2, md: 4 }}
      flexWrap={{ base: "wrap", md: "nowrap" }}
      justifyContent={{ base: "center", md: "flex-end" }}
      w={{ base: "100%", md: "auto" }}
    >
        <Tooltip
      isOpen={showTooltip}
      hasArrow
      bg="white"
      color="black"
      placement="top"
      p={4}
      borderRadius="md"
      boxShadow="md"
      label={
        <Box>
          <Box color="black" fontSize={15} fontWeight="bold" mb={1}>
            This number accepts calls only
          </Box>
          <Box color="grey.600" fontSize={15}>
            Mention the reference
          </Box>
          <Box color="red.500" fontSize={15} fontWeight="bold">
            {`"${item?.reference_number}"`}
          </Box>
        </Box>
      }
    >
      <Button
        leftIcon={<FaPhone />}
        colorScheme="purple"
        size="sm"
        variant="outline"
        onClick={handleCallClick}
        marginRight={{ base: 8, md: 0 }}
      >
        {agent?.phone || "+971565083186"}
      </Button>
    </Tooltip>
          <Button leftIcon={<FaEnvelope />} colorScheme="purple" size="sm" variant="outline" onClick={onOpen}>
            {intl.formatMessage({ id: "propertyCard.email", defaultMessage: "Email" })}
          </Button>
          <Button leftIcon={<FaWhatsapp />} colorScheme="purple" size="sm" variant="outline" onClick={handleWhatsAppClick} mr={isSavedProperties || isSavedSearches ? { base: "60px", md: "0" } : undefined}>
            {intl.formatMessage({ id: "propertyCard.whatsapp", defaultMessage: "WhatsApp" })}
          </Button>
          <Button onClick={handleLike} colorScheme="red" variant="ghost">
            {like ? <Icon as={FaHeart} color="red.500" boxSize="5" /> : <Icon as={CiHeart} color="gray.500" boxSize="5" />}
          </Button>
          {isContacted && (
          <Button leftIcon={<FaTrash />} colorScheme="red" size="sm" variant="outline" onClick={handleDelete}>
            Delete
          </Button>
        )}
        </HStack>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} size="md" zIndex={1500}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {intl.formatMessage(
              { id: "propertyCard.contactAgent", defaultMessage: "Contact {agentName}" },
              { agentName: agent?.name || intl.formatMessage({ id: "agentInfo.defaultAgentName", defaultMessage: "Agent" }) }
            )}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ContactForm agent={agent} auth={auth} property={item} onClose={onClose} setDisable={setDisable} disable={disable} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

