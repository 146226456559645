import React, { useRef } from "react";
import { Box, Text, Flex, Avatar } from "@chakra-ui/react";
import Header from "./Header";
import { useQuery } from "@tanstack/react-query";
import Carousel from "../common/Carousal"; 
import { Axios } from "../../utils/axiosMiddleware";
import { bucket_base_url } from "../../constants/constant";

export default function Testimonial() {
  const carouselRef = useRef();

  const { data: testimonials } = useQuery({
    queryKey: ["testimonial"],
    queryFn: () => Axios.get("/testimonials"),
    select: (res) => res?.data?.records,
  });

  const testimonialsPerPage = 4;

  return (
    <Box py={[6, 8, 12]} px={[3, 4, 5]} pb={{base: "50px", md: "0px"}} bg="gray.50" maxWidth="100%" mx="auto">
      <Box mb={[6, 8, 10]} ml={[0, 8, 20]} pl={[0, 0, "75px"]} textAlign={["left", "left"]}>
        <Header />
      </Box>
      {testimonials?.length > 0 && (
        <Flex
          justifyContent="center" 
          alignItems="center"
          position="relative"
          maxWidth="1900px"
          mx="auto"
          px={{ base: 0, md: 4 }} 
          ml={{ base: 0, md: 20, lg: 20, xl: "90px" }}
        >
          <Carousel cardWidth={400} cardMargin={24} ref={carouselRef}>
            {testimonials?.slice(0, testimonialsPerPage).map((item) => (
              <Box
                key={item?.id}
                bg="white"
                p={[4, 5, 6]}
                mx={{ base: 0, md: 3 }} 
                borderRadius="20px"
                boxShadow="lg"
                minW={{ base: "350px", md: "400px" }} 
                maxW={{ base: "350px", md: "400px" }}
                minH="400px"
                textAlign="left"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                <Box>
                  <Text mb={4} fontSize="2xl" fontWeight="bold">
                    {item?.reviewTitle}
                  </Text>
                  <Text mb={4} fontSize="md" fontWeight="bold">
                    "{item?.reviewText}"
                  </Text>
                </Box>
                <Text mb={4} color="yellow.400" fontSize="lg">
                  {"★".repeat(item?.rating)}{" "}
                  {"☆".repeat(5 - item?.rating)}
                </Text>
                <Flex
                  alignItems="center"
                  mt="auto"
                  pt={4}
                  borderTop="1px solid"
                  borderColor="gray.200"
                >
                  <Avatar
                    src={`${bucket_base_url}${item?.file?.filePath}`}
                    alt={item?.clientName}
                    size="lg"
                    mr={4}
                  />
                  <Box>
                    <Text fontSize="md" fontWeight="bold">{item?.clientName}</Text>
                    <Text fontSize="md" color="gray.500">
                      {item?.designation || "Client"}
                    </Text>
                  </Box>
                </Flex>
              </Box>
            ))}
          </Carousel>
        </Flex>
      )}
    </Box>
  );
}
