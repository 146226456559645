import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { message } from "antd";
import axios from "axios";
import { base_url } from "../../constants/constant";
import { setListingQueryParams } from "../../redux/reducers/propertyReducer";
import { useDispatch } from "react-redux";
import ImageCarousel from "./ImageCarousel";
import PropertyInfo from "./PropertyInfo";
import AgentInfo from "./AgentInfo";
import FullPageSkeleton from "./Skeleton";
import Header from "../Header/index";
import { FormattedMessage } from "react-intl";
import {
  Box,
  Stack,
  Flex,
  Image,
  Text,
} from "@chakra-ui/react";

export default function PropertyDetails({auth}) {
  const [property, setProperty] = useState();
  const [agent, setAgent] = useState();
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const fetchPropertyDetails = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${base_url}/property-listings/${id}`, {
        headers: { "ngrok-skip-browser-warning": true },
      });
      setProperty(response.data);
    } catch (error) {
      message.error(error.response?.data?.message || "Failed to fetch property details.");
    } finally {
      setLoading(false);
    }
  }, [id]);

  const fetchAgentDetails = useCallback(async (userId) => {
    try {
      const response = await axios.get(`${base_url}/users/${userId}`, {
        headers: { "ngrok-skip-browser-warning": true },
      });
      setAgent(response.data);
    } catch (error) {
      message.error(error.response?.data?.message || "Failed to fetch agent details.");
    }
  }, []);

  const handleBackClick = () => {
    dispatch(
      setListingQueryParams({
        skip: 0,
        take: 6,
        currentPage: 1,  
      })
    );
    navigate(-1);
  };

  useEffect(() => {
    fetchPropertyDetails();
  }, [fetchPropertyDetails]);

  useEffect(() => {
    if (property?.userId) {
      fetchAgentDetails(property.userId);
    }
  }, [property, fetchAgentDetails]);

  return (
    <Box 
      px={{ base: 4, md: 8, lg: 20 }} 
      py={{ base: 4, md: 10 }}
      maxW="1600px"
      mx="auto"
    > 
    <div className="header-wrapper">
        <Header />
    </div>
      <Flex 
        onClick={handleBackClick}
        align="center"
        pt={{ base: 20, md: 10}}
        pb={{ base: 2, md: 0 }}
        mb={{ base: 0, md: 0 }}
        cursor="pointer"
        _hover={{ opacity: 0.8 }}
      >
        <Image src="/icons/leftarrowb.png" alt="Back" h="20px" />
        <Text ml={2} fontSize={{ base: "sm", md: "lg" }}>
          <FormattedMessage id="back" />
        </Text>
      </Flex>

      {loading ? (
        <FullPageSkeleton />
      ) : (
        property && (
          <Stack spacing={{ base: 6, md: 10 }}>
            <Box w="full">
              <ImageCarousel images={property?.file} />
            </Box>
            
            <Stack
              direction={{ base: "column", lg: "row" }}
              spacing={{ base: 8, lg: 12 }}
              align="flex-start"
            >
              <Box flex={{ lg: "2" }}>
                <PropertyInfo property={property} navigate={navigate} />
              </Box>
              <Box 
                flex={{ lg: "1" }}
                w={{ base: "100%", lg: "auto" }}
                position="sticky"
                top="20px"
              >
                <AgentInfo agent={agent} property={property} auth={auth} />
              </Box>
            </Stack>
          </Stack>
        )
      )}
    </Box>
  );
}