import { Button, Flex, Box } from '@chakra-ui/react';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

const PropertyTypes = ({ selectedType, onSelectType }) => {
  const [clickedType, setClickedType] = useState(selectedType);

  const handleClick = (type) => {
    setClickedType(type); 
    onSelectType(type); 
  };

  return (
    <Box position="relative" right="10px" mr={{ base:0, md:3 }}>
    <Flex gap={4}>
      <Button
        bg={clickedType === 'sale' ? 'black' : 'white'}
        color={clickedType === 'sale' ? 'white' : 'black'}
        border="1px solid black"
        _hover={{ bg: clickedType === 'sale' ? 'gray.700' : 'gray.100' }}
        _active={{ bg: 'black', color: 'white' }}
        onClick={() => handleClick('sale')}
      >
        <FormattedMessage id="searchBar.tab_sale" defaultMessage="For Sale" />
      </Button>

      <Button
        bg={clickedType === 'rent' ? 'black' : 'white'}
        color={clickedType === 'rent' ? 'white' : 'black'}
        border="1px solid black"
        _hover={{ bg: clickedType === 'rent' ? 'gray.700' : 'gray.100' }}
        _active={{ bg: 'black', color: 'white' }}
        onClick={() => handleClick('rent')}
      >
        <FormattedMessage id="searchBar.tab_rent" defaultMessage="For Rent" />
      </Button>
    </Flex>
    </Box>
  );
};

export default PropertyTypes;
