import React, { useCallback, useEffect, useState } from "react";
import { useSwipeable } from "react-swipeable";
import { Flex, IconButton, Button } from "@chakra-ui/react";
import { ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";
import { motion } from "framer-motion";
import { useMediaQuery } from "@chakra-ui/react";

const Carousel = ({ children, cardWidth = 350, cardMargin = 20 }) => {
  const numItems = React.Children.count(children);
  const [itemsPerPage, setItemsPerPage] = useState(1);
  const [containerWidth, setContainerWidth] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const totalCardWidth = cardWidth + cardMargin;
  const containerRef = React.createRef();
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const visibleDots = 4; 
  let startX = 0;
  let startY = 0;

  useEffect(() => {
    const calculateItemsPerPage = () => {
      const containerWidth = containerRef.current?.offsetWidth || window.innerWidth;
      setContainerWidth(containerWidth);
      const calculatedItemsPerPage = Math.max(
        Math.floor(containerWidth / totalCardWidth),
        1
      );
      setItemsPerPage(calculatedItemsPerPage);
    };

    calculateItemsPerPage();
    window.addEventListener("resize", calculateItemsPerPage);

    return () => window.removeEventListener("resize", calculateItemsPerPage);
  }, [containerRef, totalCardWidth]);

  const totalPages = Math.max(Math.ceil(numItems / itemsPerPage), 1);

  const dotsStartIndex = Math.max(
    0,
    Math.min(currentPage - Math.floor(visibleDots / 2), totalPages - visibleDots)
  );
  const dotsEndIndex = Math.min(totalPages, dotsStartIndex + visibleDots);

  const handleTouchStart = (e) => {
    const touch = e.touches[0];
    startX = touch.clientX;
    startY = touch.clientY;
  };

  const handleTouchEnd = (e) => {
    const touch = e.changedTouches[0];
    const endX = touch.clientX;
    const endY = touch.clientY;

    const deltaX = endX - startX;
    const deltaY = endY - startY;

    if (Math.abs(deltaX) > Math.abs(deltaY) && Math.abs(deltaX) > 50) {
      if (deltaX > 0) {
        slide("PREV");
      } else {
        slide("NEXT");
      }
    }
  };

  const slide = useCallback(
    (direction) => {
      setCurrentPage((prevPage) => {
        if (direction === "NEXT") {
          const nextPage = Math.min(prevPage + 1, totalPages - 1);
          return nextPage;
        } else if (direction === "PREV") {
          const prevPageCalc = Math.max(prevPage - 1, 0);
          return prevPageCalc;
        }
        return prevPage;
      });
    },
    [totalPages]
  );

  const goToPage = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handlers = useSwipeable({
    onSwipedLeft: () => slide('NEXT'),
    onSwipedRight: () => slide('PREV'),
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true,
    trackTouch: true,
    delta: 10,
  });

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("touchstart", handleTouchStart, { passive: true });
      container.addEventListener("touchend", handleTouchEnd, { passive: true });
    }
  
    return () => {
      if (container) {
        container.removeEventListener("touchstart", handleTouchStart);
        container.removeEventListener("touchend", handleTouchEnd);
      }
    };
  }, [containerRef]);

  return (
    <div
      {...(isMobile ? handlers : {})}
      ref={containerRef}
      style={{
        position: "relative",
        overflow: "hidden",
        maxWidth: "100%",
      }}
    >
      <Flex
        justifyContent="center"
        alignItems="center"
        position="relative"
        overflow="hidden"
        mx="auto"
        maxW="100%"
      >
        <motion.div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
          }}
          animate={{ x: -currentPage * totalCardWidth * itemsPerPage }}
          transition={{ duration: 0.8, ease: "easeInOut" }}
        >
          {React.Children.toArray(children).map((child, index) => (
            <motion.div
              key={index}
              layout
              style={{
                width: `${cardWidth}px`,
                margin: `${cardMargin / 2}px`,
                flexShrink: 0,
                paddingBottom: "20px"
              }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              {child}
            </motion.div>
          ))}
        </motion.div>
      </Flex>
      {!isMobile && (
        <IconButton
          aria-label="Previous"
          icon={<ArrowLeftIcon />}
          position="absolute"
          top="50%"
          left="10px"
          transform="translateY(-50%)"
          onClick={() => slide("PREV")}
          zIndex="1"
          bg="rgba(0, 0, 0, 0.5)"
          _hover={{ bg: "rgba(0, 0, 0, 0.8)" }}
          color="white"
          isDisabled={currentPage === 0}
        />
      )}
      {!isMobile && (
        <IconButton
          aria-label="Next"
          icon={<ArrowRightIcon />}
          position="absolute"
          top="50%"
          right="10px"
          transform="translateY(-50%)"
          onClick={() => slide("NEXT")}
          zIndex="1"
          bg="rgba(0, 0, 0, 0.5)"
          _hover={{ bg: "rgba(0, 0, 0, 0.8)" }}
          color="white"
          isDisabled={currentPage === totalPages - 1}
        />
      )}
      <Flex justifyContent="center" mt={4}>
        {isMobile ? (
          <motion.div
            style={{
              display: "flex",
              alignItems: "center",
              overflow: "hidden",
              width: `${visibleDots * 30}px`,
              justifyContent: "center",
            }}
          >
            {Array.from({ length: totalPages }, (_, index) => index)
              .slice(dotsStartIndex, dotsEndIndex)
              .map((dotIndex) => (
                <motion.div
                  key={dotIndex}
                  style={{
                    display: "inline-block",
                    width: currentPage === dotIndex ? "20px" : "10px",
                    height: "10px",
                    backgroundColor: currentPage === dotIndex ? "black" : "gray",
                    borderRadius: "5px",
                    margin: "0 5px",
                    transition: "width 0.3s ease-in-out",
                  }}
                ></motion.div>
              ))}
          </motion.div>
        ) : (
          Array.from({ length: totalPages }, (_, index) => index).map((dotIndex) => (
            <Button
              key={dotIndex}
              onClick={() => goToPage(dotIndex)}
              mx={1}
              borderRadius="50%"
              bg={currentPage === dotIndex ? "black" : "gray.300"}
              _hover={{ bg: "gray.400" }}
              width="8px"
              height="8px"
              minW="8px"
              p={0}
            />
          ))
        )}
      </Flex>
    </div>
  );
};

export default Carousel;
