import React, { useRef } from "react";
import {
  Box,
  Flex,
  Text,
  Avatar,
  HStack,
  VStack,
  Icon,
  Center,
  Spinner,
} from "@chakra-ui/react";
import { FaStar } from "react-icons/fa";
import { useQuery } from "@tanstack/react-query";
import { Axios } from "../../../utils/axiosMiddleware";
import Carousel from "../../common/Carousal";
import { base_url } from "../../../constants/constant";

const ReviewCard = ({ name, avatar, rating, review }) => {
  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      p={4}
      boxShadow="sm"
      _hover={{ boxShadow: "md" }}
      minW="400px"
      maxW="400px"
      minH="400px"
    >
      <Flex align="center" mb={3}>
        <Avatar name={name} src={avatar} size="md" mr={3} />
        <VStack align="start" spacing={0}>
          <Text fontWeight="bold" fontSize="md">
            {name}
          </Text>
          <HStack spacing={1}>
            {[...Array(5)].map((_, i) => (
              <Icon
                key={i}
                as={FaStar}
                color={i < rating ? "yellow.400" : "gray.300"}
              />
            ))}
          </HStack>
        </VStack>
      </Flex>
      <Text fontSize="sm" color="gray.600">
        {review}
      </Text>
    </Box>
  );
};

const fetchGoogleData = async () => {
  const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY; 
  const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID; 
  const PLACE_ID = process.env.REACT_APP_PLACE_ID; 
  const url = `https://maps.googleapis.com/maps/api/place/details/json?place_id=${PLACE_ID}&fields=name,rating,reviews,user_ratings_total,formatted_address,opening_hours&key=${GOOGLE_API_KEY}&client=${GOOGLE_CLIENT_ID}`;
  const response = await Axios.get(url);

  if (response.status !== 200) {
    throw new Error("Failed to fetch data from Google API");
  }

  const data = response.data;
  return {
    reviews: data.result.reviews || [],
    rating: data.result.rating || "N/A",
    totalRatings: data.result.user_ratings_total || 0,
    address: data.result.formatted_address || "N/A",
    openingHours: data.result.opening_hours?.weekday_text || [],
  };
};

const GoogleRatings = () => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ["googleData"],
    queryFn: fetchGoogleData,
  });

  const carouselRef = useRef();

  if (isLoading) {
    return (
      <Center minH="200px">
        <Spinner size="xl" thickness="4px" color="#ECC94B" speed="0.65s" />
      </Center>
    );
  }

  if (isError) {
    return <Text mb={10} ml={20} pl={20} mt={10} textAlign="left" color="red.500">Error loading reviews or rating.</Text>;
  }

  const { reviews, rating, totalRatings, address, openingHours } = data;

  return (
    <Box w="100%" mx="auto" p={6}>
      <Text fontSize="2xl" fontWeight="bold" mb={4}>
        Overall Rating: {rating} / 5 ({totalRatings} ratings)
      </Text>
      <Text fontSize="lg" mb={2}>
        Address: {address}
      </Text>
      <Text fontSize="lg" mb={4}>
        Opening Hours:
        {openingHours.length > 0 ? (
          <ul>
            {openingHours.map((hour, index) => (
              <li key={index}>{hour}</li>
            ))}
          </ul>
        ) : (
          <Text>No opening hours available</Text>
        )}
      </Text>
      <Flex justifyContent="start" alignItems="center" position="relative" maxWidth="1900px" mx="auto">
        <Carousel cardWidth={400} cardMargin={24} ref={carouselRef}>
          {reviews.map((review, index) => (
            <ReviewCard
              key={index}
              name={review.author_name}
              avatar={review.profile_photo_url}
              rating={review.rating}
              review={review.text}
            />
          ))}
        </Carousel>
      </Flex>
    </Box>
  );
};

export { GoogleRatings, ReviewCard };

