/** @format */

import React, { useReducer, useState, useRef, useEffect } from "react";
import { Button, Col, Row, Select } from "antd";
import "../../styles/home.css";
import Property from "../../components/Home/Property";
import Feature from "../../components/Home/Feature";
import Team from "../../components/Home/Team";
import HomeContectUs from "../../components/Home/HomeContectUs";
import ExploreApartments from "../../components/PropertyTypeSection";
import ExploreTowns from "../../components/ExploreTowns";
import Blogs from "../../components/Home/Blogs";
import GoogleRatingsAndReviews from "../../components/GoogleReviews";
import CallToAction from "../../components/Home/CallToAction";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  createIntervals,
  HandleArabicClick,
  HandleEnglishClick,
} from "../../utils/Helper";
import Header from "../../components/Header";
import { FormattedMessage } from "react-intl";
import { setListingQueryParams } from "../../redux/reducers/propertyReducer";
import { useDispatch, useSelector } from "react-redux";
import Testimonial from "../../components/Testimonials/Testimonial";
import SearchBar from "../../components/Home/SearchBar";

const { Option } = Select;

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const exploreTownsRef = useRef(null);
  
  const { listingQueryParams } = useSelector((state) => state?.property);
  const [stateData, setStateData] = useState({
    propertyType: "",
    location: "",
    priceRange: "",
    priceRangeArray: [],
  });

  const locationOption = [
    {
      community: "Mohamed Bin Zayed City",
    },
    {
      community: "Al Reem Island",
    },
    {
      community: "Al Raha Beach",
    },
    {
      community: "Mussafah",
    },
    {
      community: "Sas Al Nakheel",
    },
    {
      community: "Al Bateen",
    },
    {
      community: "Masdar City",
    },
    {
      community: "Tourist Club Area",
    },
    {
      community: "Saadiyat Island",
    },
    {
      community: "Khalifa City",
    },
    {
      community: "Al Khalidiya",
    },
    {
      community: "Corniche Road",
    },
    {
      community: "Electra Street",
    },
    {
      community: "Al Mina",
    },
    {
      community: "The Marina",
    },
    {
      community: "Yas Island",
    },
    {
      community: "Al Hudayriat Island",
    },
    {
      community: "Rawdhat Abu Dhabi",
    },
    {
      community: "Capital Centre",
    },
    {
      community: "Danet Abu Dhabi",
    },
    {
      community: "Grand Mosque District",
    },
    {
      community: "Airport Road",
    },
    {
      community: "Hamdan Street",
    },
    {
      community: "Eastern Road",
    },
  ];
  const propertyType = [
    { key: "Apartment", value: "AP" },
    { key: "Duplex", value: "DX" },
    { key: " Penthouse", value: "PH" },
    { key: "Townhouse", value: "TH" },
    { key: "Villa", value: "VH" },
    { key: "Short term/ Hotel Apartment", value: "SH" },
  ];

  useEffect(() => {
    if (location.state?.scrollTo === "ExploreTowns" && exploreTownsRef.current) {
      exploreTownsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location.state]);

  const searchHandler = () => {
    const priceValue = stateData?.priceRange?.split("-");

    dispatch(
      setListingQueryParams({
        ...listingQueryParams,
        skip: 0,
        currentPage: 1,
        property_type: stateData?.propertyType,
        community: stateData?.location,
        minPrice: priceValue[0] ? priceValue[0] : 0,
        maxPrice: priceValue[1] ? priceValue[1] : 20000000,
      })
    );
    navigate("/properties");
  };
  const handlePriceSelect = async () => {
    const array = createIntervals(5500, 10800000);
    setStateData((prev) => ({ ...prev, priceRangeArray: array }));
  };
  const handlePropertyTypeChange = (value) => {
    setStateData((prev) => ({ ...prev, propertyType: value }));
  };
  const handleLocationChange = (value) => {
    setStateData((prev) => ({ ...prev, location: value }));
  };
  const handlePriceChange = (value) => {
    setStateData((prev) => ({ ...prev, priceRange: value }));
  };

  let Language;
  const lang = localStorage.getItem("lang");
  if (lang) {
    Language = "ar";
  } else {
    Language = "en";
  }
  return (
    <div>
      <div className="header-wrapper">
        <Header />
      </div>
      <div className="home-section-1">
        <div className="language_button_position">
          <Button.Group>
            <Button
              className={Language === "en" ? "enClass" : "arClass"}
              onClick={HandleEnglishClick}
            >
              English
            </Button>
            <Button
              className={Language === "ar" ? "enClass" : "arClass"}
              onClick={HandleArabicClick}
            >
              عربي
            </Button>
          </Button.Group>
        </div>

        <div className="home-heading-container">
          <p>
            <FormattedMessage id="find_your_dream" />
          </p>
        </div>
        <div className="home-paragraph-container">
          <p>
            <FormattedMessage id="home_welcome" />
          </p>
        </div>

        <SearchBar />
        {/* <div className="home-search-container">
          <Row className="home-search-row1">
            <Col xs={24} lg={6} className="col-alignment">
              <Select
                onChange={(e) => handlePropertyTypeChange(e)}
                placeholder={<FormattedMessage id="property_type" />}
                className="property-type"
              >
                {propertyType?.map((option) => (
                  <Option key={option?.key} value={option?.value}>
                    {option?.key}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col xs={24} lg={6} className="col-alignment">
              <Select
                className="property-type"
                onChange={handleLocationChange}
                placeholder={<FormattedMessage id="location" />}
              >
                {locationOption?.map((option) => (
                  <Option key={option?.community} value={option?.community}>
                    {option?.community}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col xs={24} lg={6} className="col-alignment">
              <Select
                placeholder={<FormattedMessage id="price_range" />}
                onClick={handlePriceSelect}
                onChange={handlePriceChange}
                options={stateData?.priceRangeArray}
                className="property-type"
              />
            </Col>
            <Col xs={24} lg={6} className="col-alignment">
              <Button
                className=" search-button-container"
                htmlType="submit"
                onClick={searchHandler}
              >
                <FormattedMessage id="search" />
              </Button>
            </Col>
          </Row>
        </div> */}
      </div>

      <Property />

      <ExploreApartments />

      <div style={{padding: 0}} ref={exploreTownsRef}>
        <ExploreTowns />
      </div>

      <CallToAction />

      <Feature />

      <Testimonial />

      <Blogs />

      {/* <GoogleRatingsAndReviews /> */}

      <HomeContectUs />
    </div>
  );
};

export default Home;
