import React from "react";
import { Box, Text, Badge, Image } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const BlogItem = ({ item, title, description, imageSrc, CreatedBy, category }) => {
  const navigate = useNavigate();

  return (
    <Box
      bg="white"
      borderRadius="lg"
      overflow="hidden"
      boxShadow="lg"
      textAlign="left"
      maxW={{ base:"450px", md: "400px" }}
      minH="420px"
      maxH="420px"
      mx={4}
      flexShrink="0"
      position="relative" 
    >
      <Box position="relative">
        <Image src={imageSrc} alt={title} w="100%" h="200px" objectFit="cover" />
        <Badge
          position="absolute"
          bottom="12px"
          right="12px"
          bg="white"
          borderRadius="md"
          px={2}
          py={1}
          fontWeight="bold"
          color="gray.700"
        >
          <Text>{CreatedBy}</Text>
        </Badge>
      </Box>
      <Box p={4} pb="30px" position="relative"> 
        <Text fontWeight="bold" fontSize="lg" mb={2}>
          {title}
        </Text>
        <Text fontWeight="bold" fontSize="md">
          {category}
        </Text>
        <Text fontSize="sm" color="gray.500" mt={2} noOfLines={2}>
          {description}
        </Text>

        <Box
          className="read-more" 
          position="absolute"
          top="140px"
          left="10px"
          onClick={() =>
            navigate("/blog-details", {
              state: {
                id: item,
              },
            })
          }
        >
          <FormattedMessage id="Read More" />
        </Box>
      </Box>
    </Box>
  );
};

export default BlogItem;
