import React from "react";
import { Box, Skeleton, SkeletonText, SkeletonCircle, Stack, Grid } from "@chakra-ui/react";

const PropertyDetailsSkeleton = () => {
  return (
    <Box padding={{ base: "20px", md: "40px 80px 80px 80px" }}>
      <Box mb={8}>
        <Stack direction="row" align="center" mb={4}>
          <Skeleton height="20px" width={{ base: "70px", md: "100px" }} />
        </Stack>
      </Box>

      <Grid
        templateColumns={{ base: "1fr", md: "2fr 1fr" }} 
        gap={8}
        mb={8}
      >
        <Box>
          <Skeleton height={{ base: "200px", md: "400px" }} borderRadius="md" />
        </Box>
        <Stack spacing={4}>
          <Skeleton height="20px" width="50%" />
          <SkeletonText noOfLines={4} spacing="4" />
        </Stack>
      </Grid>

      <Box>
        <Skeleton height="40px" width={{ base: "150px", md: "200px" }} mb={4} />
        <Grid
          templateColumns={{ base: "repeat(2, 1fr)", md: "repeat(4, 1fr)" }}
          gap={6}
        >
          {Array(6)
            .fill(0)
            .map((_, i) => (
              <Box key={i} textAlign="center">
                <SkeletonCircle size={{ base: "8", md: "10" }} mb={2} />
                <Skeleton height="10px" width={{ base: "50px", md: "60px" }} />
              </Box>
            ))}
        </Grid>
      </Box>

      <Box mt={8}>
        <SkeletonText noOfLines={6} spacing="4" />
      </Box>
    </Box>
  );
};

export default PropertyDetailsSkeleton;
