import React from "react";
import PropertyDetailsSkeleton from "./PropertyInfo";
import AgentInfoSkeleton from "./AgentInfo";
import ImageCarouselSkeleton from "./ImageCarousel";

const FullPageSkeleton = () => {
  return (
    <div>
      <ImageCarouselSkeleton />
      <PropertyDetailsSkeleton />
      <AgentInfoSkeleton />
    </div>
  );
};

export default FullPageSkeleton;
