import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

const Header = () => {
  return (
    <Box px={{base: 4, md: 2}} pt={{base: "50px", md: 0}}>
      <Heading as="h2" size="xl" fontWeight="bold" color="black" mb={2}>
      <FormattedMessage id="header_title" defaultMessage="People Love Living with SandSeekers" />
      </Heading>
      <Text fontSize="lg" color="gray.500">
      <FormattedMessage 
        id="header_subtitle" 
        defaultMessage="Home is where the heart is, and our clients have found their perfect homes through SandSeekers." 
      />
      </Text>
    </Box>
  );
};

export default Header;
